<template>
  <v-dialog max-width="900"  v-model="show" @click:outside="closeDialog" scrollable>
    <v-card class="dialogCardClient" :loading="loading">
      <v-card-title class="mb-5">Novo Cliente
          <v-spacer></v-spacer>
          <v-icon @click="$emit('close')">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <ValidationObserver ref="observer">
        <v-row>
          <v-col cols="7">
            <v-card-subtitle class="pl-0 pb-2 pt-0">Nome</v-card-subtitle>
            <ValidationProvider rules="isNotBlank" v-slot="v">

            <v-text-field
              dense
              outlined
              hide-details="auto"
              lazy-rules
              v-model="client.name"
            ></v-text-field>
<span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
          </v-col>
          <v-col cols="5">
            <v-card-subtitle class="pl-0 pb-2 pt-0">CPF</v-card-subtitle>
            <ValidationProvider rules="isCPFValid" v-slot="v">

            <v-text-field
              dense
              outlined
              hide-details="auto"
              lazy-rules
              v-model="client.CPF"
              v-mask="'###.###.###-##'"
            ></v-text-field>
<span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="7">
            <v-card-subtitle class="pl-0 pb-2 pt-0">E-mail</v-card-subtitle>
            <ValidationProvider rules="isEmailValid" v-slot="v">

            <v-text-field
              dense
              outlined
              hide-details="auto"
              lazy-rules
              v-model="client.email"
            ></v-text-field>
<span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
          </v-col>
          <v-col cols="5">
            <div class="d-flex">
              <v-card-subtitle class="pl-0 pb-2 pt-0">Contato</v-card-subtitle>
              <v-spacer></v-spacer>
              <v-checkbox
                label="Whatsapp"
                hide-details="auto"
                v-model="client.hasWhats"
                style="lineheight: normal; margin-top: 0px; padding: 0"
              ></v-checkbox>
            </div>
            <ValidationProvider rules="isNotBlank|isContactValid" v-slot="v">

            <v-text-field
              outlined
              dense
              hide-details="auto"
              no-data-text="Nenhuma informação disponível"
              lazy-rules
              v-model="client.contact"
              v-mask="phoneMask"
            ></v-text-field>
<span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="7">
            <v-row>
              <v-col cols="4">
                <v-card-subtitle class="pl-0 pb-2 pt-0">CEP</v-card-subtitle>
                <ValidationProvider rules="isCEPValid" v-slot="v">

                <v-text-field
                  dense
                  outlined
                  hide-details="auto"
                  lazy-rules
                  v-model="clientAddress.CEP"
                  v-mask="'##.###-###'"
                  @blur="autocompleteAddress(v.errors)"
                  
                ></v-text-field>
<span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </v-col>
              <v-col cols="8">
                <v-card-subtitle class="pl-0 pb-2 pt-0">Rua</v-card-subtitle>
                <ValidationProvider rules="" v-slot="v">

                <v-text-field
                  dense
                  lazy-rules
                  outlined
                  hide-details="auto"
                  v-model="clientAddress.street"
                ></v-text-field>
<span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5">
            <v-row>
              <v-col cols="4">
                <v-card-subtitle class="pl-0 pb-2 pt-0">N°</v-card-subtitle>
                <ValidationProvider rules="" v-slot="v">

                <v-text-field
                  dense
                  type="number"
                  lazy-rules
                  outlined
                  hide-details="auto"
                  v-model="clientAddress.number"
                  id="numberField"
                ></v-text-field>
<span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </v-col>
              <v-col cols="8">
                <v-card-subtitle class="pl-0 pb-2 pt-0"
                  >Complemento</v-card-subtitle
                >
                <ValidationProvider rules="" v-slot="v">

                <v-text-field
                  lazy-rules
                  dense
                  outlined
                  hide-details="auto"
                  v-model="clientAddress.complement"
                ></v-text-field>
<span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="7">
            <v-row>
              <v-col cols="4">
                <v-card-subtitle class="pl-0 pb-2 pt-0">Bairro</v-card-subtitle>
                <ValidationProvider rules="" v-slot="v">

                <v-text-field
                  dense
                  outlined
                  hide-details="auto"
                  lazy-rules
                  v-model="clientAddress.neighbourhood"
                ></v-text-field>
<span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </v-col>
              <v-col cols="8">
                <v-card-subtitle class="pl-0 pb-2 pt-0">Cidade</v-card-subtitle>
                <ValidationProvider rules="" v-slot="v">
                <v-text-field
                  dense
                  outlined
                  hide-details="auto"
                  lazy-rules
                  v-model="clientAddress.city"
                ></v-text-field>

<span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5">
            <v-card-subtitle class="pl-0 pb-2 pt-0">Estado</v-card-subtitle>
            <ValidationProvider rules="" v-slot="v">

            <v-text-field
              dense
              outlined
              hide-details="auto"
              lazy-rules
              v-model="clientAddress.state"
            ></v-text-field>
<span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
          </v-col>
        </v-row>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark depressed color="blue" @click="saveNewClient"
          >Confirmar</v-btn
        >
        <v-btn text color="blue" @click="$emit('close')">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CLIENT_MASKS_MIXIN from '@/utils/mixins/masks.js';

export default {
  mixins: [CLIENT_MASKS_MIXIN],
  props: { value: Boolean },
  data() {
    return {
      loading: false,
      client: {
        name: '',
        CPF: '',
        email: '',
        contact: '',
        hasWhats: true,
      },
      clientAddress: {
        CEP: '',
        street: '',
        number: '',
        complement: '',
        neighbourhood: '',
        city: '',
        state: '',
      },
      rules: [
        (value) => !!value || 'Required.',
        (value) => (value && value.length >= 3) || 'Min 3 characters',
        (value) => value != '' || 'Escolha a data',
      ],
    };
  },
  methods: {
    async autocompleteAddress(errors){
      this.loading = true
      if (errors.length == 0) {
        try {
          const address = await this.findAddressByZipCode(this.clientAddress.CEP)
          this.clientAddress.city = address.city
          this.clientAddress.neighbourhood = address.neighborhood
          this.clientAddress.state = address.state
          this.clientAddress.street = address.street
          document.querySelector('#numberField').focus()
        } catch (error) {
          this.loading = false
        }
      }
      this.loading = false
    },
    closeDialog() {
      this.$emit('close', false);
    },
    async saveNewClient(){
      this.loading = true
      const isValid = await this.$refs.observer.validate()
      if (this.$refs.observer._data.flags.valid) {
        const content = {'client': this.client, 'clientAddress': this.clientAddress}
        this.$emit('saveNewClient', content)
      }
    }
  },
  computed: {
    phoneMask() {
      return this.getPhoneMask(this.client.contact);
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style>
.dialogCardClient .mdi-checkbox-marked::before {
  font-weight: 900;
  content: url('https://api.iconify.design/mdi-checkbox-marked-circle.svg?color=%23007aff&height=24');
  padding-top: 3px;
}
.dialogCardClient .mdi-checkbox-blank-outline::before {
  font-weight: 900;
  padding-top: 3px;
  content: url('https://api.iconify.design/mdi-checkbox-blank-circle-outline.svg?color=rgba(0%2C0%2C0%2C0.6)&height=24');
}
</style>