<template>
  <div class="px-8 pt-7 clients-div">
    <div class="topBar d-flex">
      <div class="titleText">Clientes</div>
      <v-spacer></v-spacer>
      <div class="search">
        <v-text-field
          append-icon="mdi-magnify"
          v-model="search"
          outlined
          dense
          placeholder="Pesquisar em clientes (nome/contato/CPF)"
          class="filterButton"
          hide-details
          style="height: 40px;"
        >
        </v-text-field>
    </div>
      <v-btn
        v-if="userHasPermission('change_client')"
        color="#177DFF"
        depressed
        dark
        height="40"
        @click="newClientModal = true"
        >Novo cliente</v-btn
      >
      <v-btn
        v-else
        color="#177DFF"
        depressed
        dark
        height="40"
        class="disabled-button"
        >Novo cliente</v-btn
      >
    </div>
    <div class="tableDiv d-flex">
      <v-data-table
        :items="showedArray(this.clientArray)"
        :items-per-page="rowsPerSize"
        :headers="header"
        :search="search"
        no-data-text="Nenhum resultado encontrado"
        @click:row="rowClicked"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ datetimeBr(item.createdAt).split(' ')[0] }}
        </template>
        <template v-slot:[`item.attendencesAmount`]="{ item }">
          {{ getTotalAttendences(item.id) }}
        </template>
        <template v-slot:[`item.contact`]="{ item }">
          <input
            disabled
            v-mask="phoneMask(item)"
            type="text"
            :value="item.contact"
            style="max-width: 150px"
          />
        </template>

        <template v-slot:[`item.totalClientPrice`]="{ item }">
          {{ currencyBr(getTotalClientPrice(item.id)) }}
        </template>

        <template v-slot:[`footer.page-text`]="footer">
          <div>
            {{ footer.pageStart }} - {{ footer.pageStop }} de
            {{ footer.itemsLength }}
          </div>
        </template>
      </v-data-table>
    </div>
    <NewClient
      v-if="newClientModal"
      @saveNewClient="saveNewClient"
      @close="newClientModal = false"
      v-model="newClientModal"
    ></NewClient>
    <ClientInfo
      v-if="clientInfoModal"
      :onlyInfo="false"
      @close="clientInfoModal = false"
      :clientObj="selectedClient"
      @confirmTrue="updateClient"
      v-model="clientInfoModal"
    ></ClientInfo>
  </div>
</template>

<script>
import CLIENT_MASKS_MIXIN from '@/utils/mixins/masks.js';

import NewClient from '../modals/NewClient';
import ClientInfo from '../modals/ClientInfo';

import { createNamespacedHelpers } from 'vuex';
const {
  mapState: mapStateClients,
  mapActions: mapActionsClients,
  mapGetters: mapGettersClients,
} = createNamespacedHelpers('clients');
const { mapGetters: mapGettersAttendences } =
  createNamespacedHelpers('attendences');

export default {
  mixins: [CLIENT_MASKS_MIXIN],
  components: { NewClient, ClientInfo },
  data() {
    return {
      selectedClient: null,
      clientInfoModal: null,
      search: '',
      newClientModal: null,
      viewPeople: ['Todas as pessoas'],
      viewPeopleModel: 'Todas as pessoas',
      rowsPerSize: 4,
      header: [
        { text: 'Nome', value: 'name' },
        { text: 'CPF', value: 'CPF' },
        { text: 'Data de registro', value: 'createdAt' },
        { text: 'Contato', value: 'contact' },
        { text: 'Atendimentos', value: 'attendencesAmount' },
        { text: 'Valor total', value: 'totalClientPrice' },
        // { text: 'cpfCnpj', value: 'CPF', align: ' d-none ' },
      ],
      clientArray: [],
      filteredStart: false,
    };
  },
  methods: {
    ...mapActionsClients([
      'createClient',
      'createClientAddress',
      'editClientAddress',
      'editClient',
    ]),
    async updateClient(client) {
      const newAddress = await this.editClientAddress({
        clientAddress: client.clientAddress,
      });
      client.clientAddress = newAddress;
      const newClient = await this.editClient({ client });
      this.clientInfoModal = false;
      this.newClientModal = false;
    },
    showedArray(clients) {
      return clients;
    },
    phoneMask(item) {
      return this.getPhoneMask(item.contact);
    },
    rowClicked(event) {
      this.selectedClient = event;
      this.clientInfoModal = true;
    },
    getTotalAttendences(id) {
      return this.getAttendencesByClient(id).length;
    },
    getTotalClientPrice(id) {
      let cost = 0;
      this.getAttendencesByClient(id).forEach(
        (el) => (cost += this.getAttendencePrice(el))
      );
      return cost;
    },
    getAttendencePrice(atte) {
      let cost = 0;
      atte.item.forEach((el) => (cost += this.getAttendenceItemPrice(el)));
      return cost;
    },
    async saveNewClient(content) {
      try {
        const clientObj = await this.createClient({ client: content.client });
        content.clientAddress['clientId'] = clientObj.id;
        const addressObj = await this.createClientAddress({
          clientAddress: content.clientAddress,
        });
      } catch (error) {
        //console.log(error);
      }
      this.clientInfoModal = false;
      this.newClientModal = false;
      this.updateTable();
    },
    changeEndTime(evt) {
      this.endTime = evt;
    },
    changeStartTime(evt) {
      this.startTime = evt;
    },
    updateTable() {
      if (this.$route.path == '/clientes') {
        const maximumRows = this.clientArray.length;
        const main = document.querySelector('.tableDiv');
        //console.log(main.offsetHeight);
        const totalHeight = main.offsetHeight - 137;
        const rowsFitting = Math.floor(totalHeight / 40);
        const totalRows = Math.min(rowsFitting, maximumRows);
        this.rowsPerSize = totalRows;
        const counterRows = document.querySelector(
          '.tableDiv .v-select__selection.v-select__selection--comma'
        );
        counterRows.innerHTML = totalRows;
      }
    },
  },
  watch: {
    clients(newVal) {
      this.clientArray = newVal;
      this.updateTable();
    },
  },
  computed: {
    ...mapStateClients(['clients']),
    ...mapGettersClients(['getClientsByName']),
    ...mapGettersAttendences(['getAttendencesByClient']),
    tableClientsArray() {
      let array = Object.values(this.clientArray);
      if (this.filterByName) {
        array = this.getClientsByName(this.filterByName);
      }
      return array;
    },
  },

  async mounted() {
    this.clientArray = [];
    //console.log(this.clients);
    this.clientArray = this.clients;
    this.updateTable();

    var self = this;
    window.onresize = function (event) {
      self.updateTable();
    };
  },
};
</script>

<style>
.clients-div {
  display: flex;
  flex-flow: column;
  height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  overflow: hidden;
}

.clients-div .topBar {
  flex: 0 1 auto;
  width: 100%;
  flex-wrap: wrap;
}

.clients-div .tableDiv {
  flex: 1 1 auto;
  overflow: hidden;
}

.clients-div .hasBlackIcon {
  max-width: 450px;
  height: 40px;
}

.clients-div .hasBlackIcon .v-icon {
  color: black !important;
}

.clients-div .titleText {
  font-weight: 500;
  font-size: 26px;
}

.clients-div .v-text-field--outlined fieldset {
  color: #e8e8e8 !important;
  transition: none !important;
}

/* Shadow Control */
.clients-div .menuable__content__active {
  border: solid 1px #c9c9c9 !important;
  box-shadow: none !important;
}

.clients-div .filterButton {
  background-color: white;
  max-width: 230px;
  margin-right: 15px;
  border-radius: 6px;
}

.clients-div .filterButton input::placeholder {
  font-size: 14px;
}

.clients-div tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.clients-div .v-data-table {
  border: 1px solid #e9ecef;
  width: 100%;
  height: fit-content;
  margin-top: 20px;
}

.clients-div .v-data-table .v-data-footer__select {
  display: none;
}

.clients-div .v-data-table .v-data-footer {
  height: 59px;
}

.clients-div .v-data-table .v-data-footer__pagination {
  margin-left: auto;
  margin-right: 30px;
}
.search .v-input__control{
  width: 360px !important;
}
.clients-div .filterButton{
  max-width: none;
}
</style>
